/* eslint-disable camelcase */
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import plusFill from "@iconify/icons-eva/plus-fill";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import inboxOutline from "@iconify/icons-eva/inbox-outline";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import OrdersListHead from "../components/OrdersListHead";
import OrdersListToolbar from "../components/OrdersListToolbar";

import orderService from "../services/orderService";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name_Customer", label: "Cliente", alignRight: false },
  { id: "code_OrderCode", label: "Código de Pedido", alignRight: false },
  { id: "date_OS", label: "Data de Modificação", alignRight: false },
  { id: "status_Order", label: "Status", alignRight: false },
  { id: "name_Company", label: "Unidade", alignRight: false },
  { id: "whatsapp_Customer", label: "Contato", alignRight: false },
  { id: "", label: "Detalhes" },
];

const StageNAME: any = {
  1: "BAIXA",

  2: "LENSOMETRO",

  3: "PERDA",

  4: "RETRABALHO",

  5: "VERIFICACAO VISUAL",

  6: "REPROVADA NO RECEBIMENTO",

  7: "CANCELADA",

  8: "CONTINUAR PROCESSO",

  9: "ENVIADA",
  CADASTRADA: "CADASTRADA",
  RECEBIDA: "RECEBIDA",
};
// ----------------------------------------------------------------------

export default function Services() {
  const parse: any = localStorage.getItem("user");
  const user = JSON.parse(parse);

  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [data, setData]: any = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [notFirstQuery, setNotFirstQuery] = useState(false);
  const [queryStartEnd, setQueryStartEnd] = useState([
    "0",
    rowsPerPage.toString(),
  ]);

  const [params, setParams]: any = useState({
    value: "",
    startDate: undefined,
    endDate: undefined,
    stage: undefined,
    initialCompany:
      user.idCompany === 2
        ? "PA"
        : user.idCompany === 3
        ? "NE"
        : user.idCompany === 4
        ? "SP"
        : undefined,
  });

  useEffect(() => {
    orderService
      .getAll(queryStartEnd[0], queryStartEnd[1], params)
      .then((data: any) => setData(data.data))
      .catch((error: any) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStartEnd, params.startDate, params.endDate, params.initialCompany]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        notFirstQuery
          ? orderService
              .getAll(queryStartEnd[0], queryStartEnd[1], params)
              .then((data: any) => setData(data.data))
              .catch((error: any) => console.error(error))
          : setNotFirstQuery(true),
      500
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.value]);

  const handleChangePage = (event: any, newPage: any) => {
    const finalPage = newPage > 0 ? page + 1 : page - 1;
    setQueryStartEnd([
      ((finalPage + 1) * rowsPerPage - rowsPerPage).toString(),
      rowsPerPage.toString(),
    ]);
    setPage(finalPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const finalRowsPerPage = parseInt(event.target.value);
    setQueryStartEnd([
      ((page + 1) * finalRowsPerPage - finalRowsPerPage).toString(),
      finalRowsPerPage.toString(),
    ]);
    setRowsPerPage(finalRowsPerPage);
    setPage(0);
  };

  function callWhatsapp(number: any) {
    const url = "https://api.whatsapp.com/send?phone=55" + number;
    window.open(url, "_blank");
  }

  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - data.length) : 0;

  return (
    <Page title="Meus pedidos | Optools">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" gutterBottom>
            Ordem de serviço
          </Typography>
        </Stack>

        <Stack direction={["column", "row"]} justifyContent="space-between">
          <OrdersListToolbar
            company={params.initialCompany}
            stage={params.stage}
            filterName={params.value}
            filterReset={() =>
              setParams({
                value: "",
                startDate: undefined,
                endDate: undefined,
                initialCompany:
                  user.idCompany === 2
                    ? "PA"
                    : user.idCompany === 3
                    ? "NE"
                    : user.idCompany === 4
                    ? "SP"
                    : undefined,
              })
            }
            onFilterName={(value: string) => {
              setPage(0);
              setQueryStartEnd(["0", rowsPerPage.toString()]);
              setParams({ ...params, value: value });
            }}
            onFilterDate={(startDate: string, endDate: string) => {
              setPage(0);
              setQueryStartEnd(["0", rowsPerPage.toString()]);
              setParams({
                ...params,
                startDate: startDate.length ? startDate : undefined,
                endDate: endDate.length ? endDate : undefined,
              });
            }}
            onFilterCompany={(value: string) => {
              setPage(0);
              setQueryStartEnd(["0", rowsPerPage.toString()]);
              setParams({
                ...params,
                initialCompany: value !== "ALL" ? value : undefined,
              });
            }}
            onFilterStatus={(value: string) => {
              setPage(0);
              setQueryStartEnd(["0", rowsPerPage.toString()]);
              setParams({
                ...params,
                stage: value !== "ALL" ? value : undefined,
              });
            }}
          />
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrdersListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {data.length ? (
                    data
                      .slice(0, rowsPerPage)
                      .map((row: any, index: number) => {
                        const {
                          date_OS,
                          name_Company,
                          status_Order,
                          name_Customer,
                          code_OrderCode,
                          initials_Company,
                          whatsapp_Customer,
                        } = row;

                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={code_OrderCode}
                            role="checkbox"
                          >
                            <TableCell align="left">
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{ color: "#004A78", fontSize: "14px" }}
                              >
                                {name_Customer}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{ color: "#004A78", fontSize: "14px" }}
                              >
                                {code_OrderCode}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="body1"
                                noWrap
                                sx={{ color: "#707070", fontSize: "14px" }}
                              >
                                {date_OS ? date_OS : "Sem data para exibir"}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{ color: "#004A78", fontSize: "14px" }}
                              >
                                {status_Order
                                  ? StageNAME[status_Order]
                                  : "CADASTRADA"}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{ color: "#004A78", fontSize: "14px" }}
                              >
                                {name_Company
                                  ? `${initials_Company} - ${name_Company}`
                                  : "Não definido"}
                              </Typography>
                            </TableCell>
                            <TableCell align="left" sx={{ padding: "11px" }}>
                              <Button
                                color="primary"
                                disabled={whatsapp_Customer ? false : true}
                                onClick={() => callWhatsapp(whatsapp_Customer)}
                              >
                                <WhatsAppIcon />
                              </Button>
                            </TableCell>

                            <TableCell align="left" sx={{ padding: "11px" }}>
                              <Button
                                sx={{
                                  padding: 0,
                                  minWidth: "36px",
                                  height: "36px",
                                  borderRadius: "18px",
                                  marginLeft: "12%",
                                }}
                                color="secondary"
                                variant="contained"
                                onClick={async () => {
                                  const data = await orderService.getById(
                                    code_OrderCode
                                  );
                                  const actions = await orderService.getActions(
                                    code_OrderCode
                                  );

                                  navigate("/dashboard/buscar", {
                                    state: {
                                      ...data.data,
                                      code_Order: code_OrderCode,
                                      actions: actions.data,
                                    },
                                  });
                                }}
                              >
                                <Icon width="20px" icon={plusFill} />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Stack
                          width="100%"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Icon
                            icon={inboxOutline}
                            width="56px"
                            color="#909090"
                          />
                          <Typography
                            mt={1}
                            variant="body1"
                            noWrap
                            sx={{ color: "#909090" }}
                          >
                            Nenhum item encontrado...
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                  {emptyRows > 0 ? (
                    <TableRow style={{ height: 59 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  ) : undefined}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            page={0}
            component="div"
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[20, 40, 60]}
            labelRowsPerPage="Linhas por página:"
            backIconButtonProps={{ disabled: !page }}
            nextIconButtonProps={{
              disabled:
                (page + 1) * rowsPerPage >= data[0]?.total_Order ||
                data.length < rowsPerPage,
            }}
            labelDisplayedRows={(rowsInfo) => {
              const totalPages = Math.ceil(
                rowsInfo.count / rowsPerPage
              ).toString();
              console.log(params);
              return (
                <>
                  {`Página: ${page + 1}${
                    !params.value.length &&
                    !params.prepaid &&
                    !params.situation &&
                    !params.initialCompany
                      ? `/${totalPages === "0" ? "1" : totalPages}`
                      : ""
                  }`}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {!params.value.length &&
                  !params.prepaid &&
                  !params.situation &&
                  !params.initialCompany
                    ? `Total: ${rowsInfo.count}`
                    : ""}
                </>
              );
            }}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={data?.length ? data[0].total_Order : 0}
          />
        </Card>
      </Container>
    </Page>
  );
}
