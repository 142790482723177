/* eslint-disable no-const-assign */
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { useState, useEffect, useRef } from "react";
import searchFill from "@iconify/icons-eva/search-fill";
import { styled } from "@mui/system";
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Stack,
  Button,
  Container,
  OutlinedInput,
  InputAdornment,
  Box,
} from "@mui/material";

import Page from "../../../components/Page";
import FormDataOrder from "../components/FormDataOrder";
import AlertMessage from "../../../components/alert/alert";
import orderService from "../services/orderService";

const SearchStyle = styled(OutlinedInput)(({ theme }: any) => ({
  width: 320,
  height: 48,
  float: "left",
  marginBottom: "12px",
  borderRadius: "40px",
  backgroundColor: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 300, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function NewOrder() {
  let loaded = useRef(false);
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [changer, setChanger] = useState(0);
  const [showError, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [selectData, setSelectData]: any = useState({});
  const [errorAlert, setErrorAlert] = useState<string | null>(null);

  const RegisterSchema = Yup.object().shape({
    code_Order: Yup.string().required("Campo obrigatório"),
  });

  let formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      code_Order: "",
    },
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      formik.resetForm();
      setChanger(changer === 1 ? 2 : 1);

      const data: any = await orderService
        .getById(values.code_Order.toUpperCase())
        .then((res) => res)
        .catch(() => false);

      const actions: any = await orderService
        .getActions(values.code_Order.toUpperCase())
        .then((res) => res)
        .catch(() => false);
      if (data?.data?.idCustomer_Order)
        await orderService
          .getLens(data.data.idCustomer_Order)
          .then((data) => {
            setSelectData({ ...selectData, lens: data.data });
          })
          .catch((err) => console.log(err));
      if (data) {
        navigate("/dashboard/buscar", {
          state: {
            ...data.data,
            code_Order: values.code_Order.toUpperCase(),
            actions: actions?.data,
          },
        });
      } else {
        navigate("/dashboard/buscar", {
          replace: true,
          state: {
            ...data.data,
            code_Order: values.code_Order.toUpperCase(),
            actions: actions?.data,
          },
        });
      }
    },
  };

  const formik = useFormik(formikValues);

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      setChanger(0);
      orderService.getStandard().then((data) => {
        orderService
          .getLens(state?.idCustomer_Order)
          .then((lens) => {
            const dataFull = {
              ...data.data,
              lens: lens.data,
            };
            setSelectData(dataFull);
          })
          .catch((err) => console.log(err));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectData]);

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  return (
    <Page title="Pedidos | Optools">
      <Container>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction="row" mb="8px" mt={2}>
              <SearchStyle
                placeholder="EEE-00001"
                {...getFieldProps("code_Order")}
                error={Boolean(touched.code_Order && errors.code_Order)}
                inputProps={{
                  maxLength: 9,
                  style: { textTransform: "uppercase" },
                  autoFocus: true,
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{ color: "text.disabled" }}
                    />
                  </InputAdornment>
                }
              />

              <Button
                sx={{
                  height: "48px",
                  paddingX: "32px",
                  marginLeft: "18px",
                  borderRadius: "40px",
                  background: "#004A78",
                  "&:hover": {
                    bgcolor: "#226C9A",
                  },
                }}
                type="submit"
                color="secondary"
                variant="contained"
              >
                Buscar
              </Button>
            </Stack>
          </Form>
        </FormikProvider>

        <FormDataOrder
          isValid={isValid}
          changer={changer}
          selects={selectData}
          setError={(bool: boolean) => setError(bool)}
          setSuccess={(bool: boolean) => setSuccess(bool)}
          setValid={(stateBol: boolean) => setIsValid(stateBol)}
          errorAlertHandler={setErrorAlert}
        />

        <Stack direction="row" justifyContent="flex-end" mt={3}>
          <Button
            sx={{
              height: "60px",
              borderRadius: "40px",
              background: "transparent",
              marginBottom: "0px",
              color: "#004A78",
              marginRight: "10px",
              minWidth: "100px",
            }}
            component={RouterLink}
            to="/dashboard/operacional"
            variant="outlined"
            color="secondary"
          >
            Voltar
          </Button>
        </Stack>
      </Container>
      {showSuccess && (
        <AlertMessage open={true} message="Sucesso na Operação!" />
      )}
      {showError && (
        <AlertMessage open={true} message="Erro ao efetuar registro" />
      )}
      {!!errorAlert && <AlertMessage open={true} message={errorAlert || ""} />}
    </Page>
  );
}
