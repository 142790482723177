import { AxiosResponse } from "axios";
import http from "../../../services/http-common";

class OrderService {
  // eslint-disable-next-line class-methods-use-this
  async saveOrder(data: any) {
    try {
      const order = {
        lensesBrand_Order: data.lensesBrand_Order || "",
        idMaker_Order: data.idMaker_Order,
        lensesAmount_Order: data.lensesAmount_Order,
        idBlock_Order: data.idBlock_Order || "",
        idPhotosensitivity_Order: data.idPhotosensitivity_Order,
        idDesign_Order: data.idDesign_Order,
        idLens_Order: data.idLens_Order,
        idMaterial_Order: data.idMaterial_Order,
        idFocus_Order: data.idFocus_Order,

        // Prescription
        rightSpherical_Order: data.rightSpherical_Order,
        rightCylindrical_Order: data.rightCylindrical_Order,
        rightAxis_Order: data.rightAxis_Order,
        rightAddition_Order: data.rightAddition_Order,
        leftSpherical_Order: data.leftSpherical_Order,
        leftCylindrical_Order: data.leftCylindrical_Order,
        leftAxis_Order: data.leftAxis_Order,
        leftAddition_Order: data.leftAddition_Order,
        os_clientNumber: data.os_clientNumber || "",
        // Extra
        observation_Order: data.observation_Order || "",

        idOrderType_Order: data.idOrderType_Order,
        idDeadline_Order: data.idDeadline_Order,
        billingPlace_Order: data.billingPlace_Order,
        idCompany_Order: data.idCompany_Order,
      };

      return http.put(`/order/update/${data.code_Order}`, order);
    } catch (error) {
      console.log(error);
    }
  }

  getTag(orderCode: string | number) {
    return http.get<any, AxiosResponse<{ base64: string }>>(
      `order/label/printing/${orderCode}`
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getAll(offset: string, limit: string, params: any) {
    return http.get(`order/operational/find/${offset}/${limit}`, {
      params: params,
    });
  }

  getSelect(type: string) {
    return http.get(`/${type}/find`);
  }

  getLens(idCustomer: string) {
    return http.get(`/team/lens/${idCustomer}`);
  }

    getStandard() {
      return http.get("/order_standard");
    }

  getById(id: string) {
    return http.get(`/order/${id}`);
  }

  getActions(id: string) {
    return http.get(`/order/actions/${id}`);
  }

  async getIp() {
    const parse: any = localStorage.getItem("user");
    const user = JSON.parse(parse);

    return http.get(`ip/find`).then((res: any) => {
      if (user.idCompany === 4)
        return res.data.find((item: any) => item.initials_Company === "SP")
          .number_Ip;
      else if (user.idCompany === 3)
        return res.data.find((item: any) => item.initials_Company === "NE")
          .number_Ip;
      else
        return res.data.filter((item: any) => item.initials_Company === "PA");
    });
  }
}

export default new OrderService();
